var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("accessory.accessory-edit"),
          backButtonText: _vm.$t("navigation.back")
        }
      }),
      _c(
        "el-form",
        {
          ref: "accessoryForm",
          staticClass: "form-container",
          attrs: {
            model: _vm.accessoryData,
            "label-position": "left",
            "label-width": "220px",
            "status-icon": ""
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "name",
                label: _vm.$t("accessory.accessory-name"),
                rules: [
                  {
                    required: true,
                    message: _vm.$t("accessory.form-rules.type-name")
                  }
                ]
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  "auto-complete": "on",
                  placeholder: _vm.$t("accessory.type-accessory-name")
                },
                model: {
                  value: _vm.accessoryData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.accessoryData, "name", $$v)
                  },
                  expression: "accessoryData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("accessory.icon") } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("accessory.select-icon")
                      },
                      model: {
                        value: _vm.accessoryData.icon,
                        callback: function($$v) {
                          _vm.$set(_vm.accessoryData, "icon", $$v)
                        },
                        expression: "accessoryData.icon"
                      }
                    },
                    _vm._l(_vm.getIcons, function(icon, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: icon.title, value: icon.value }
                        },
                        [
                          _c("span", { staticClass: "icon-select-layout" }, [
                            _c("img", {
                              staticClass: "icon-select",
                              attrs: {
                                src: "/assets/icons/" + icon.value + ".png"
                              },
                              on: { error: _vm.onImageError }
                            }),
                            _vm._v(_vm._s(icon.title))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("span", [
                  _c("img", {
                    staticClass: "icon-contact",
                    attrs: {
                      src: "/assets/icons/" + _vm.accessoryData.icon + ".png"
                    },
                    on: { error: _vm.onImageError }
                  })
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("accessory.room") } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("accessory.select-room") },
                  model: {
                    value: _vm.accessoryData.roomId,
                    callback: function($$v) {
                      _vm.$set(_vm.accessoryData, "roomId", $$v)
                    },
                    expression: "accessoryData.roomId"
                  }
                },
                _vm._l(_vm.$store.getters.rooms, function(room) {
                  return _c("el-option", {
                    key: room.id,
                    attrs: { label: room.name, value: room.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: { prop: "favorite", label: _vm.$t("accessory.favorite") }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.accessoryData.favorite,
                  callback: function($$v) {
                    _vm.$set(_vm.accessoryData, "favorite", $$v)
                  },
                  expression: "accessoryData.favorite"
                }
              })
            ],
            1
          ),
          _vm.accessoryData.category != 26
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        prop: "active",
                        label: _vm.$t("accessory.active")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.accessoryData.active,
                          callback: function($$v) {
                            _vm.$set(_vm.accessoryData, "active", $$v)
                          },
                          expression: "accessoryData.active"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isKnxDriver
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "category",
                    label: _vm.$t("accessory.accessory-type"),
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("accessory.form-rules.select-category")
                      }
                    ]
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("accessory.select-accessory-type")
                      },
                      on: { change: _vm.onSelectCategory },
                      model: {
                        value: _vm.accessoryData.category,
                        callback: function($$v) {
                          _vm.$set(_vm.accessoryData, "category", $$v)
                        },
                        expression: "accessoryData.category"
                      }
                    },
                    _vm._l(_vm.categoryNames, function(category) {
                      return _c("el-option", {
                        key: category.value,
                        attrs: {
                          label: _vm.$t(category.title),
                          value: category.value
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDisplayCategory,
                  expression: "showDisplayCategory"
                }
              ],
              attrs: {
                prop: "displayCategory",
                label: _vm.$t("accessory.display-category"),
                rules: [
                  {
                    required: true,
                    message: _vm.$t(
                      "accessory.form-rules.select-display-category"
                    )
                  }
                ]
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("accessory.select-display-category")
                  },
                  model: {
                    value: _vm.accessoryData.displayCategory,
                    callback: function($$v) {
                      _vm.$set(_vm.accessoryData, "displayCategory", $$v)
                    },
                    expression: "accessoryData.displayCategory"
                  }
                },
                _vm._l(_vm.displayCategories, function(category) {
                  return _c("el-option", {
                    key: category.value,
                    attrs: {
                      label: _vm.$t(category.title),
                      value: category.value
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(_vm.detailComponent, {
            ref: "detailComp",
            tag: "component",
            attrs: { accessoryData: _vm.accessoryData, editMode: "" }
          }),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.saveAccessory($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accessory.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }