<template>
  <div>

    <app-navigation :title="$t('accessory.accessory-edit')" :backButtonText="$t('navigation.back')"></app-navigation>


    <el-form :model="accessoryData" ref="accessoryForm" class="form-container" label-position="left" label-width="220px" status-icon>

      <el-form-item prop="name" :label="$t('accessory.accessory-name')" style="width: 400px" :rules="[{required: true, message: $t('accessory.form-rules.type-name')}]">
        <el-input type="text" v-model="accessoryData.name" auto-complete="on" :placeholder="$t('accessory.type-accessory-name')">
        </el-input>
      </el-form-item>


      <el-form-item :label="$t('accessory.icon')">

        <el-col :span="8">
          <el-select v-model="accessoryData.icon" filterable :placeholder="$t('accessory.select-icon')">
            <el-option v-for="(icon, index) in getIcons" :key="index" :label="icon.title" :value="icon.value">
              <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="16">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + accessoryData.icon + '.png'" class="icon-contact">
              </span>
        </el-col>

      </el-form-item>


      <el-form-item :label="$t('accessory.room')">
        <el-select v-model="accessoryData.roomId" :placeholder="$t('accessory.select-room')">
          <el-option v-for="room in $store.getters.rooms" :label="room.name" :value="room.id" :key="room.id"></el-option>
        </el-select>
      </el-form-item>



      <el-form-item prop="favorite" :label="$t('accessory.favorite')" style="width: 400px">
        <el-switch v-model="accessoryData.favorite" ></el-switch>
      </el-form-item>
      <div v-if="accessoryData.category != 26">
        <el-form-item prop="active" :label="$t('accessory.active')" style="width: 400px">
          <el-switch v-model="accessoryData.active" ></el-switch>
        </el-form-item>
      </div>

      <el-form-item v-if="isKnxDriver" prop="category" :label="$t('accessory.accessory-type')" :rules="[{required: true, message: $t('accessory.form-rules.select-category')}]">

        <el-select v-model="accessoryData.category" :placeholder="$t('accessory.select-accessory-type')" @change="onSelectCategory">
          <el-option v-for="category in categoryNames" :label="$t(category.title)" :value="category.value" :key="category.value"></el-option>
        </el-select>

      </el-form-item>


      <el-form-item v-show="showDisplayCategory" prop="displayCategory" :label="$t('accessory.display-category')" :rules="[{required: true, message: $t('accessory.form-rules.select-display-category')}]">

        <el-select v-model="accessoryData.displayCategory" :placeholder="$t('accessory.select-display-category')">
          <el-option v-for="category in displayCategories" :label="$t(category.title)" :value="category.value" :key="category.value"></el-option>
        </el-select>

      </el-form-item>


      <component ref="detailComp" :is="detailComponent" :accessoryData="accessoryData" editMode></component>

      <el-form-item style="text-align: left">
        <el-button type="primary" @click.native.prevent="saveAccessory" :loading="loading">{{ $t('accessory.save') }}</el-button>
      </el-form-item>

    </el-form>



  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import LogoSwitchInputs from "./components/logo/LogoSwitchInputs";
import LogoSensorInputs from "./components/logo/LogoSensorInputs";
import LogoShutterInputs from "./components/logo/LogoShutterInputs";
import KnxSwitchInputs from "./components/knx/KnxSwitchInputs";
import KnxDimmerInputs from "./components/knx/KnxDimmerInputs";
import KnxTunableWhiteInputs from "./components/knx/KnxTunableWhiteInputs";
import KnxShutterInputs from "./components/knx/KnxShutterInputs";
import KnxThermostatInputs from "./components/knx/KnxThermostatInputs";
import KnxPunchInputs from "./components/knx/KnxPunchInputs";
import KnxBinaryInputs from "./components/knx/KnxBinaryInputs";
import KnxAdvThermostatInputs from "./components/knx/KnxAdvThermostatInputs";
import KnxSensorInputs from "./components/knx/KnxSensorInputs";
import { className, group } from "@/utils/accessory-utils";
import { accessoryMixin } from "./components/accessoryMixin";
import GpioSensorInputs from "./components/gpio/GpioSensorInputs";
import GpioSwitchInputs from "./components/gpio/GpioSwitchInputs";
import ModbusSwitchInputs from "./components/modbus/ModbusSwitchInputs";
import ModbusSensorInputs from "./components/modbus/ModbusSensorInputs";
import ModbusAdvancedThermostatInputs from "./components/modbus/ModbusAdvancedThermostatInputs";
import KnxStatusDisplayInputs from "./components/knx/KnxStatusDisplayInputs";
import KnxRGBWDimmerInputs from "./components/knx/KnxRGBWDimmerInputs";
import KnxAudioInputs from "./components/knx/KnxAudioInputs";
import CoolmasterThermostatInputs from "./components/coolmaster/CoolmasterThermostatInputs";
import { modbusCategories } from "@/utils/modbus";
import { logoCategories } from "@/utils/logo";
import { knxCategories } from "@/utils/knx";
import { gpioCategories } from "@/utils/gpio";
import { coolmasterCategories } from "@/utils/coolmaster";
import {
  ACCESSORY_CATEGORY_CONTACTOR,
  ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
  ACCESSORY_CATEGORY_ELEVATOR,
  ACCESSORY_CATEGORY_GAS_VALVE,
  ACCESSORY_CATEGORY_SENSOR_DOOR,
  ACCESSORY_CATEGORY_SENSOR_GAS,
  ACCESSORY_CATEGORY_SENSOR_LEAK,
  ACCESSORY_CATEGORY_SENSOR_MOTION,
  ACCESSORY_CATEGORY_SENSOR_SMOKE,
  ACCESSORY_CATEGORY_SENSOR_WINDOW,
  ACCESSORY_CATEGORY_SIREN,
  ACCESSORY_CATEGORY_SWITCH,
  ACCESSORY_CATEGORY_WATER_VALVE,
  ACCESSORY_CATEGORY_DIMMER,
  ACCESSORY_CATEGORY_TUNABLE_WHITE,
  ACCESSORY_CATEGORY_SHUTTER,
  ACCESSORY_CATEGORY_PUNCH_BUTTON,
  ACCESSORY_CATEGORY_BINARY_BUTTON,
  ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
  ACCESSORY_CATEGORY_STATUS_DISPLAY,
  ACCESSORY_CATEGORY_RGBW,
  ACCESSORY_CATEGORY_AUDIO,
} from "../../utils/accessory-utils";
import {
  DRIVER_TYPE_MODBUS,
  DRIVER_TYPE_LOGO,
  DRIVER_TYPE_KNXTP,
  DRIVER_TYPE_KNXIP,
  DRIVER_TYPE_GPIO,
  DRIVER_TYPE_COOLMASTER,
} from "@/utils/driver-utils";

export default {
  name: "AccessoryEdit",

  mixins: [accessoryMixin],

  components: {
    "app-navigation": Navigation,
    LogoSwitchInputs,
    LogoSensorInputs,
    LogoShutterInputs,
    KnxShutterInputs,
    KnxSwitchInputs,
    KnxDimmerInputs,
    KnxTunableWhiteInputs,
    KnxThermostatInputs,
    KnxPunchInputs,
    KnxBinaryInputs,
    KnxAdvThermostatInputs,
    KnxSensorInputs,
    KnxStatusDisplayInputs,
    GpioSensorInputs,
    GpioSwitchInputs,
    ModbusSwitchInputs,
    ModbusSensorInputs,
    ModbusAdvancedThermostatInputs,
    CoolmasterThermostatInputs,
    KnxRGBWDimmerInputs,
    KnxAudioInputs,
  },

  data: function() {
    return {
      detailComponent: {},
      accessoryData: {},
      loading: false,
      error: false,

      originalCategory: -1,
      previousCategory: -1,
      relativeTriggers: [],
    };
  },

  computed: {
    categoryNames() {
      switch (this.getDriverType()) {
        case DRIVER_TYPE_LOGO:
          return logoCategories();
        case DRIVER_TYPE_KNXIP:
        case DRIVER_TYPE_KNXTP:
          return knxCategories();
        case DRIVER_TYPE_GPIO:
          return gpioCategories();
        case DRIVER_TYPE_MODBUS:
          return modbusCategories();
        case DRIVER_TYPE_COOLMASTER:
          return coolmasterCategories();
      }
    },
    showDisplayCategory() {
      if (!this.accessoryData.category) return false;
      return (
        this.accessoryData.category == 1 ||
        this.accessoryData.category == 27 ||
        this.accessoryData.category == 28
      );
    },
    isKnxDriver() {
      let driverType = this.getDriverType();
      return driverType == DRIVER_TYPE_KNXIP || driverType == DRIVER_TYPE_KNXTP;
    },
    getIcons() {
      const category = this.accessoryData.category;
      if (category) {
        const group = this.group(category);

        const myIcons = this.icons.filter((icon) => icon.type == group);
        const otherIcons = this.icons.filter((icon) => icon.type != group);

        return [...myIcons, ...otherIcons];
      }
      return [];
    },
  },

  watch: {
    "accessoryData.category": function(newVal, oldVal) {
      console.log("watch", newVal, oldVal);

      this.previousCategory = oldVal;
    },
  },

  methods: {
    className,
    group,

    getDriverType() {
      return this.$store.getters.drivers.find(
        (driver) => driver.id == this.accessoryData.driverId
      ).driverType;
    },

    checkTriggers() {
      this.$store.getters.triggers.forEach((trigger) => {
        console.log("checkTriggers", trigger.name);

        if (trigger.condition) {
          this.checkTriggersRecursively(trigger, trigger.condition);
        }
      });
    },

    checkTriggersRecursively(trigger, condition) {
      if (condition.conditions) {
        condition.conditions.forEach((cond) =>
          this.checkTriggersRecursively(trigger, cond)
        );
      } else {
        if (
          condition.category === "accessory" &&
          condition.identifier == this.accessoryData.id
        ) {
          if (!this.relativeTriggers.includes(trigger.name))
            this.relativeTriggers.push(trigger.name);
        }
      }
    },

    onSelectCategory(category) {
      console.log("onSelectCategory : " + this.accessoryData.category);

      this.$confirm(
        this.$t("accessory.warning-delete-knx-address"),
        this.$t("alert.title.warning"),
        {
          confirmButtonText: this.$t("alert.button.ok"),
          cancelButtonText: this.$t("alert.button.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          var dispCategory = this.displayCategory(category);
          console.log("Display Category : " + dispCategory);
          if (dispCategory >= 0)
            this.accessoryData.displayCategory = dispCategory;

          delete this.accessoryData.details.knxAddresses;

          this.updateDetailComponent();
        })
        .catch(() => {
          this.accessoryData.category = this.previousCategory;
        });
    },

    updateDetailComponent() {
      console.log("updateDetailComponent");

      const driverType = this.getDriverType();
      switch (driverType) {
        case DRIVER_TYPE_KNXIP:
        case DRIVER_TYPE_KNXTP:
          if (
            [
              ACCESSORY_CATEGORY_SWITCH,
              ACCESSORY_CATEGORY_SIREN,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = KnxSwitchInputs;
          else if (this.accessoryData.category == ACCESSORY_CATEGORY_DIMMER)
            this.detailComponent = KnxDimmerInputs;
          else if (this.accessoryData.category == ACCESSORY_CATEGORY_TUNABLE_WHITE)
            this.detailComponent = KnxTunableWhiteInputs;
          else if (this.accessoryData.category == ACCESSORY_CATEGORY_RGBW)
            this.detailComponent = KnxRGBWDimmerInputs;
          else if (this.accessoryData.category == ACCESSORY_CATEGORY_AUDIO)
            this.detailComponent = KnxAudioInputs;
          else if (this.accessoryData.category == ACCESSORY_CATEGORY_SHUTTER)
            this.detailComponent = KnxShutterInputs;
          else if (
            this.accessoryData.category == ACCESSORY_CATEGORY_PUNCH_BUTTON
          )
            this.detailComponent = KnxPunchInputs;
          else if (
            this.accessoryData.category == ACCESSORY_CATEGORY_BINARY_BUTTON
          )
            this.detailComponent = KnxBinaryInputs;
          else if (
            this.accessoryData.category ==
            ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT
          )
            this.detailComponent = KnxAdvThermostatInputs;
          else if (
            this.accessoryData.category == ACCESSORY_CATEGORY_STATUS_DISPLAY
          )
            this.detailComponent = KnxStatusDisplayInputs;
          else if (
            [
              ACCESSORY_CATEGORY_SENSOR_WINDOW,
              ACCESSORY_CATEGORY_SENSOR_DOOR,
              ACCESSORY_CATEGORY_SENSOR_GAS,
              ACCESSORY_CATEGORY_SENSOR_LEAK,
              ACCESSORY_CATEGORY_SENSOR_MOTION,
              ACCESSORY_CATEGORY_SENSOR_SMOKE,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = KnxSensorInputs;
          else this.detailComponent = KnxThermostatInputs;

          break;
        case DRIVER_TYPE_LOGO:
          if (
            [
              ACCESSORY_CATEGORY_SWITCH,
              ACCESSORY_CATEGORY_CONTACTOR,
              ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
              ACCESSORY_CATEGORY_ELEVATOR,
              ACCESSORY_CATEGORY_GAS_VALVE,
              ACCESSORY_CATEGORY_SIREN,
              ACCESSORY_CATEGORY_WATER_VALVE,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = LogoSwitchInputs;
          else if (
            [
              ACCESSORY_CATEGORY_SENSOR_WINDOW,
              ACCESSORY_CATEGORY_SENSOR_DOOR,
              ACCESSORY_CATEGORY_SENSOR_GAS,
              ACCESSORY_CATEGORY_SENSOR_LEAK,
              ACCESSORY_CATEGORY_SENSOR_MOTION,
              ACCESSORY_CATEGORY_SENSOR_SMOKE,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = LogoSensorInputs;
          else this.detailComponent = LogoShutterInputs;
          break;
        case DRIVER_TYPE_GPIO:
          if (
            [
              ACCESSORY_CATEGORY_SWITCH,
              ACCESSORY_CATEGORY_CONTACTOR,
              ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
              ACCESSORY_CATEGORY_ELEVATOR,
              ACCESSORY_CATEGORY_GAS_VALVE,
              ACCESSORY_CATEGORY_SIREN,
              ACCESSORY_CATEGORY_WATER_VALVE,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = GpioSwitchInputs;
          else if (
            [
              ACCESSORY_CATEGORY_SENSOR_WINDOW,
              ACCESSORY_CATEGORY_SENSOR_DOOR,
              ACCESSORY_CATEGORY_SENSOR_GAS,
              ACCESSORY_CATEGORY_SENSOR_LEAK,
              ACCESSORY_CATEGORY_SENSOR_MOTION,
              ACCESSORY_CATEGORY_SENSOR_SMOKE,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = GpioSensorInputs;
          break;
        case DRIVER_TYPE_MODBUS:
          if (this.accessoryData.category == ACCESSORY_CATEGORY_SWITCH)
            this.detailComponent = ModbusSwitchInputs;
          else if (
            [
              ACCESSORY_CATEGORY_SENSOR_MOTION,
              ACCESSORY_CATEGORY_SENSOR_DOOR,
            ].includes(this.accessoryData.category)
          )
            this.detailComponent = ModbusSensorInputs;
          else if (
            this.accessoryData.category ==
            ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT
          ) {
            this.detailComponent = ModbusAdvancedThermostatInputs;
          }
          break;
        case DRIVER_TYPE_COOLMASTER:
          this.detailComponent = CoolmasterThermostatInputs;
          break;
      }

      console.log(this.detailComponent);
    },

    saveAccessoryInternal() {
      this.$refs.accessoryForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.accessoryData.details.hasOwnProperty("knxAddresses")) {
            if (!this.$refs.detailComp.validate()) {
                this.loading = false;
                return;
              }
            this.accessoryData.details.knxAddresses = this.accessoryData.details.knxAddresses.map(
              (addr) => {
                if (addr.readAddress)
                  addr.readAddress = addr.readAddress.replace(/_/g, "");
                if (addr.writeAddress)
                  addr.writeAddress = addr.writeAddress.replace(/_/g, "");
                return addr;
              }
            );
          }

          this.accessoryData.group = this.group(this.accessoryData.category);

          const data = {
            type: this.className(this.accessoryData.category),
            properties: this.accessoryData,
          };
          this.$store
            .dispatch("editAccessory", data)
            .then((response) => {
              this.loading = false;
              this.$message({
                type: "success",
                message: this.$t(response.message),
              });

              this.$router.push({ name: "Accessories" });
            })
            .catch((error) => {
              this.loading = false;
              this.$message({
                type: "error",
                message: this.$t(error),
              });
            });
        }
      });
    },

    saveAccessory(e) {
      this.relativeTriggers = [];

      console.log("saveAccessory", this.className(this.accessoryData.category));

      if (
        this.className(this.accessoryData.category) !=
        this.className(this.originalCategory)
      ) {
        this.checkTriggers();
      }

      if (this.relativeTriggers.length > 0) {
        this.$confirm(
          this.$t("accessory.warning-delete-triggers") +
            " " +
            this.relativeTriggers.join(", "),
          this.$t("alert.title.warning"),
          {
            confirmButtonText: this.$t("alert.button.ok"),
            cancelButtonText: this.$t("alert.button.cancel"),
            type: "warning",
          }
        ).then(() => {
          this.saveAccessoryInternal();
        });
      } else {
        this.saveAccessoryInternal();
      }
    },
  },

  created() {
    this.accessoryData = this.$store.getters.accessories.find(
      (accessory) => accessory.id == this.$route.params.id
    );

    this.originalCategory = this.accessoryData.category;

    console.log("accessoryData", this.accessoryData);

    this.updateDetailComponent();

    this.$store.dispatch("getTriggers");
  },
};
</script>

<style scoped>
.form-container {
  margin-right: 20px;
  margin-left: 20px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 25px 25px 25px;
  border: 1px solid #eaeaea;
}

.icon-select-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-select {
  filter: brightness(0.45);
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.icon-contact {
  filter: brightness(0.45);
  width: 40px;
  height: 40px;
}
</style>
